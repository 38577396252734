@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes scroll-left-to-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right-to-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scroll-left-to-right {
  display: flex;
  animation: scroll-left-to-right 10s linear infinite;
}

.scroll-right-to-left {
  display: flex;
  animation: scroll-right-to-left 10s linear infinite;
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
}
@media only screen and (max-width: 639px) {
  .mobile {
    display: none;
  }
  .checkout {
    min-height: 80vh;
    overflow-y: scroll;
  }
  .invert {
    filter: invert(1);
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .checkout {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .checkout::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .head-media {
    gap: 4em;
  }
 }








 
.btn-pink {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  /* width: fit-content; */
  color: white;
  padding: 0.5rem;
  /* box-shadow: 5px 5px 7px 0px #ef476f3f; */
  font-size: 3rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 500;
  border: solid 3px transparent;
  position: relative;
  z-index: 100;
  /* border-radius: 5px; */
}

.btn-pink::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.8s;
}

.btn-pink:hover::before {
  transform: scaleX(1);
}

.btn-pink:hover {
  border: solid 3px #115a15;
  /* color: green; */
  scale: 1.1;
} 



* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes scroll-left-to-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right-to-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scroll-left-to-right {
  display: flex;
  animation: scroll-left-to-right 10s linear infinite;
}

.scroll-right-to-left {
  display: flex;
  animation: scroll-right-to-left 10s linear infinite;
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
}
@media only screen and (max-width: 639px) {
  .mobile {
    display: none;
  }
  .checkout {
    min-height: 80vh;
    overflow-y: scroll;
  }
  .invert {
    filter: invert(1);
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .checkout {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .checkout::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .head-media {
   gap:4em;
}
}

.bg-achha {
  background-image: url('http://gpssystemindia.in/images/dummy/img2.png');
  background-size: cover; /* Adjusts the image to cover the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}


.d-shadow {
  box-shadow: 0 4px 80px rgb(255, 20, 118), /* Light blue shadow */
              0 6px 20px rgb(195, 201, 116), /* Orange shadow */
              0 8px 30px rgb(44, 21, 255); /* Red shadow */
  transition: transform 0.3s;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 6px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 300px;
  max-width: 100%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.login-form .form-group {
  margin-bottom: 15px;
}

.login-form label {
  display: block;
  border: 1px;
  margin-bottom: 5px;
}

.login-form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background: #0056b3;
}
